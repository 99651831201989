@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

$space-big: 20px;
$space-medium: 15px;

div[class*="styles_content"].modalWrapper {
  max-width: 360px;
}

.modalHeader {
  background-color: $color-white-gray;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
}

.modalBody {
  padding: $space-big;
}

.modalDescription {
  margin-bottom: $space-medium;
}
